import React from "react"
import { Jumbotron } from "../components/home/jumbotron"
import News from "../components/home/news"
import { Gmap } from "../components/gmap"

export default function HomePage() {
  return (
    <>
      <Jumbotron />
      <News />
      <Gmap />
    </>
  )
}
