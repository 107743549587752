import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const newsQuery = graphql`
  query NewsQuery {
    allContentfulNews(limit: 6, sort: {order: DESC, fields: createdAt}){
      edges {
        node {
          id
          cover {
            gatsbyImageData( placeholder: BLURRED, formats: [AUTO, WEBP])
          }
          title
          slug
          body {
            raw
          }
          createdAt(formatString: "DD.MM.YYYY")          
        }
      }
    }
  }
`;

export default function News() {
  return (
    <StaticQuery
      query={newsQuery}
      render={data => {
        const newsNodes = data.allContentfulNews.edges
        const news = newsNodes.map(nodes => {
          const node = nodes.node
          const image = getImage(node.cover)
          return (
            <article key={node.id} className="mx-auto mt-4 max-w-max">
              <div className="overflow-hidden rounded shadow-lg">
                <Link to={`/news/${node.slug}`}>
                  <GatsbyImage image={image} alt={node.title} />
                </Link>
                <div className="p-4 h-28 ">
                  <div className="text-base font-semibold text-red-500"><Link to={`/news/${node.slug}`}>{node.title}</Link></div>
                  <div className="inline-flex items-center space-x-2 text-gray-400 ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span className="text-sm font-semibold">{node.createdAt}</span>
                  </div>
                </div>
              </div>
            </article>
          )
        })

        return (
          <section className="px-4 mt-10 text-justify md:mx-auto md:max-w-7xl">
            <div className="inline-flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="inline-block w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
              </svg>
              <h2 className="content-center text-lg font-semibold tracking-tight md:text-2xl">Les dernières infos</h2>
            </div>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {news}
            </div>
          </section>
        )
      }}
    />
  )
}

