import * as React from "react"

export function Gmap() {
  return (
    <section className="relative w-full mx-auto mt-14">
      <div className="w-full mx-auto text-center border-b border-gray-300 md:absolute md:right-0 min-w-min md:w-96">
        <div className="w-full h-full p-4 text-gray-800 bg-white">
          <div className="font-medium leading-5 tracking-tighter">
            <h5 className="font-bold">Lieu Dit Le Treuil</h5>
            <p className="">87260 ST HILAIRE BONNEVAL</p>
            <p>Tél: 06 72 02 61 20 - Mail: domaine-du-treuil@wanadoo.fr</p>
          </div>
        </div>
      </div>

      <div>
        <iframe
          title="Le domaine equestre du treuil"
          className="w-full max-w-full h-60"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22287.87028538116!2d1.373592006471015!3d45.71136211599453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f92fa5cda4d0a7%3A0xb69488a892c88340!2sLe+Treuil%2C+87260+Saint-Hilaire-Bonneval%2C+France!5e0!3m2!1sen!2sus!4v1551700156061"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen>
        </iframe>
      </div>
    </section>
  )
}