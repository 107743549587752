import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export function Jumbotron() {
  return (
    <div className="mx-auto mt-0.5 w-full md:max-w-7xl">
      <StaticImage
        className="w-full"
        src="../../../static/images/bg/bg.jpg"
        placeholder="blurred"
        layout="fullWidth"
        alt=""
      // formats={["auto", "webp", "avif"]}
      />
    </div>
  )
}
